import axios from "@/api/baseRequest.js";

//增加课题
export const apiAddTopic = (formData) => axios.post("/api/1/admin/topic/add", formData);

//删除课题
export const apiDeleteTopic = (formData) => axios.post("/api/1/admin/topic/delete", formData);

//修改课题
export const apiEditTopic = (formData) => axios.post("/api/1/admin/topic/edit", formData);
