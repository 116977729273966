<template>
    <div style="padding: 10px 20px">
        <el-form ref="form" status-icon label-width="90px" label-position="left">
            <el-form-item prop="phone" label="修改课题：">
                <el-input v-model="topic.title" maxlength="20" placeholder="请输入课题的标题" show-word-limit type="text" />
            </el-form-item>
        </el-form>
        <el-button type="primary" @click="editTopic()" style="width: 100%" ref="saveButton">保存</el-button>
    </div>
</template>

<script>
import { apiEditTopic } from "@/api/admin/topic.js";

export default {
    //声明数据源
    data() {
        return {
            topic: {
                id: "",
                title: ""
            }
        };
    },
    methods: {
        getTopic() {
            this.topic.id = this.$route.query.id;
            this.topic.title = this.$route.query.title;
        },
        async editTopic() {
            let formData = new FormData();
            //去除首尾空格
            this.topic.title = this.topic.title.trim();
            if (this.topic.title == "") {
                this.$message({ message: "请输入标题", type: "error" });
                return;
            }
            formData.append("token", this.$store.state.token);
            formData.append("id", this.topic.id);
            formData.append("title", this.topic.title);

            //调用接口
            const response = await apiEditTopic(formData);
            if (response.data.code === 200) {
                //调用父页面关闭layer
                window.parent.postMessage({ message: "修改课题成功", code: "200" }, "*");
            } else {
                this.$message({ message: response.data.message, type: "error" });
            }
        }
    },
    mounted() {
        this.getTopic();
    }
};
</script>
